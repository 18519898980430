namespace Shared

type RatesMapping =
    { AccessCode: string
      ChainCode: string
      Program: string
      Public: int
      RateCode: string
      RatePlanCodes: RatePlanCode list }

and PrivateRateMapping =
    { Private: int
      RateCode: string
      Program: string
      Type: int
      AllowsIataGuarantee: int
      AccessCode: string
      RatePlanCodes: RatePlanCode list }

and RatePlanCode =
    { GdsCode: string
      RatePlanCode: string }

and Pagination = { PageNumber: int; ElemPerPage: int }

and FilteredRates =
    { RatesList: RatesMapping list
      NumberTotalElements: int }

and Query = { Page: Pagination; Public: bool }

and PrivateRate =
    { ContractName: string
      Program: string
      RateCode: string
      AmadeusCode: string
      SabreCode: string
      GalileoCode: string
      WorldSpanCode: string
      PagasusCode: string }

and Token = { email: string }

and User =
    { Email: string
      Role: string
    }

module Route =
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName

type IRatesApi =
    { search: string * Query -> Async<FilteredRates>
      postRate: PrivateRateMapping -> Async<string>
      updateRate: PrivateRateMapping -> Async<Result<string,string>> 
      deleteRate: string -> Async<string>
      file: unit -> Async<byte[]>
      ckeckSignatureToken: string -> Async<bool>
      checkEmailRight: string -> Async<bool>
      getUser: string -> Async<User>
      getClientId: unit -> Async<string>}
