module App

open Elmish
open Elmish.React
open Elmish.Navigation

#if DEBUG
open Elmish.Debug
open Elmish.HMR
open Index
#endif


Program.mkProgram Index.init Index.update Index.view
|> Program.toNavigable (UrlParser.parseHash Index.Navigation.pageParser) Index.Navigation.urlUpdate
#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.withReactSynchronous "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run
