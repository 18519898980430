module ExtranetUIKit

open Fable.Core
open Fable.Core.JsInterop
open Fable.React
open Feliz

module Link =

    type Prop =
        | Ref of obj
        | OnClick of (unit -> unit)

    let link props children : ReactElement =
        let propsObject = keyValueList CaseRules.LowerFirst props
        ofImport "Link" "@d-edge/extranet-ui-react" propsObject children

module Popover =

    let popover props children : ReactElement =
        let propsObject = keyValueList CaseRules.LowerFirst props
        ofImport "Popover" "@d-edge/extranet-ui-react" propsObject children

    let popoverNav children : ReactElement =
        ofImport "PopoverNav" "@d-edge/extranet-ui-react" [] children

    let popoverBody children : ReactElement =
        ofImport "PopoverBody" "@d-edge/extranet-ui-react" [] children

    let popoverAction children : ReactElement =
        ofImport "PopoverAction" "@d-edge/extranet-ui-react" [] children

    let popoverNavClose props : ReactElement =
        let propsObject = keyValueList CaseRules.LowerFirst props
        ofImport "PopoverNavClose" "@d-edge/extranet-ui-react" propsObject []

module Modal =

    let modal props children : ReactElement =
        let propsObject = keyValueList CaseRules.LowerFirst props
        ofImport "Modal" "@d-edge/extranet-ui-react" propsObject children

    let modalNav children : ReactElement =
        ofImport "ModalNav" "@d-edge/extranet-ui-react" [] children

    let modalBody children : ReactElement =
        ofImport "ModalBody" "@d-edge/extranet-ui-react" [] children

    let modalAction children : ReactElement =
        ofImport "ModalAction" "@d-edge/extranet-ui-react" [] children

    let modamNavBack props : ReactElement =
        let propsObject = keyValueList CaseRules.LowerFirst props
        ofImport "ModalNavBack" "@d-edge/extranet-ui-react" propsObject []

    let modamNavClose props : ReactElement =
        let propsObject = keyValueList CaseRules.LowerFirst props
        ofImport "ModalNavClose" "@d-edge/extranet-ui-react" propsObject []

module Action =

    let button props children : ReactElement =
        let propsObject = keyValueList CaseRules.LowerFirst props
        ofImport "Button" "@d-edge/extranet-ui-react" propsObject children

    