module PrivateRates

open Elmish
open Shared
open Fable.Remoting.Client
open CommonComponent
open Fable.Core.JsInterop
open NLog

type Rate = 
    { ContractName: string
      Program: string
      RateCode: string
      AmadeusCode: string
      SabreCode: string
      GalileoCode: string
      WorldSpanCode: string
      PegasusCode: string
      }

type RateMappingModel =
    { Rate: RatesMapping
      IsEditable: bool
      EditableRate: RatesMapping }

type ModalInfo =
    { Message: string
      RateCode: string
      Confirmation: bool }

and Model =
    { Rates: RateMappingModel list
      Input: string
      PlanCode: RatePlanCode list
      Remainder: int
      PageMax: int
      Query: Query
      IsLoading: bool
      Modal: ModalInfo option
      User: User
      Status: string
      PostResult: string
      ErrorMessage: string option
    }

and Msg =
    | SetUser of User
    | AddRate of Rate
    | AddRateSucceeded of string
    | AddRateFailed of exn
    | SetRates
    | GotRatesSucceeded of FilteredRates
    | GotRatesFailed of exn
    | SetElementPerPage of int
    | NextList
    | PreviousList
    | SetInput of string
    | Search of string
    | SetEditable of RateMappingModel
    | SetRatePlanCode of RateMappingModel * string * string
    | ModifyRate of RateMappingModel
    | ModifyRateSuccess of Result<string,string> 
    | ModifyRateFail of exn
    | DeleteRate of string
    | DeleteRateSucceeded of string
    | DeleteRateFail of exn
    | DownloadFile
    | DownloadedFile of byte []
    | OpenModal of string * string
    | CloseModal
    | CloseBanner

let RatesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IRatesApi>

let init () : Model * Cmd<Msg> =
    let model =
        { Input = ""
          Rates = []
          PlanCode = []
          Remainder = 0
          PageMax = 0
          Query =
            { Page = { PageNumber = 0; ElemPerPage = 10 }
              Public = false }
          IsLoading = true
          Modal = None
          User = { Email = ""; Role = ""}
          Status = ""
          PostResult = ""
          ErrorMessage = None
        }

    let cookie = getCookie "token"
    let (decode: Token) = decodeJwtResponse (cookie)

    let cmdSetUser = Cmd.OfAsync.perform RatesApi.getUser decode.email SetUser
    let cmd = Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) GotRatesSucceeded GotRatesFailed

    model, Cmd.batch [cmdSetUser; cmd ]

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | SetUser user ->
        let model = { model with User = user }
        Fable.Core.JS.console.log(" user email : ", user.Email)
        Fable.Core.JS.console.log(" user role : ", user.Role)

        model, Cmd.none
    | AddRate newrate ->
        let privRate =
            { Private = 1
              RateCode = newrate.RateCode
              Program = newrate.Program
              Type = 0
              AllowsIataGuarantee = 0
              AccessCode =
                (String.concat "-" [ "GDS-"; newrate.ContractName ])
                    .Replace(" ", "-")
              RatePlanCodes =
                [ { GdsCode = "1A"
                    RatePlanCode = newrate.AmadeusCode }
                  { GdsCode = "AA"
                    RatePlanCode = newrate.SabreCode }
                  { GdsCode = "UA"
                    RatePlanCode = newrate.GalileoCode }
                  { GdsCode = "1P"
                    RatePlanCode = newrate.WorldSpanCode }
                  { GdsCode = "WB"
                    RatePlanCode = newrate.PegasusCode } ] }

        let cmd =
            Cmd.OfAsync.either RatesApi.postRate privRate Msg.AddRateSucceeded Msg.AddRateFailed

        model, cmd
    | AddRateSucceeded rate ->
        let model =
            { model with
                Status = "Success"
                PostResult =
                    "The new rate has been added with sucess"
                }

        model, Cmd.none
    | AddRateFailed exn ->
        let model =
            { model with
                Status = "Failed"
                PostResult = exn.Message }
        model, Cmd.none
    | GotRatesSucceeded (filter) ->
        { model with
            Rates =
                filter.RatesList
                |> List.map (fun x ->
                    { Rate = x
                      IsEditable = false
                      EditableRate = x })
            Remainder = filter.NumberTotalElements % model.Query.Page.ElemPerPage
            PageMax =
                filter.NumberTotalElements
                / model.Query.Page.ElemPerPage
            IsLoading = false },
        Cmd.none
    | GotRatesFailed ex ->
        Fable.Core.JS.console.log ex.Message
        { model with
            ErrorMessage = Some "An error occurred during data loading. Please check your connection or try again later."
            IsLoading = false },
        Cmd.none
    | SetRates ->
        let model =
            { model with
                Input = ""
                Query = { model.Query with Page = { model.Query.Page with PageNumber = 0 } } }

        model, Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed
    | SetInput value -> { model with Input = value }, Cmd.none
    | SetElementPerPage value ->
        let model =
            { model with Query = { model.Query with Page = { model.Query.Page with ElemPerPage = value } } }

        let cmd =
            Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | NextList ->
        let model =
            { model with
                Query =
                    { model.Query with
                        Page =
                            { model.Query.Page with
                                PageNumber =
                                    if (model.Query.Page.PageNumber < model.PageMax) then
                                        model.Query.Page.PageNumber + 1
                                    else
                                        model.Query.Page.PageNumber } } }

        let cmd =
            Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | PreviousList ->
        let model =
            { model with
                Query =
                    { model.Query with
                        Page =
                            { model.Query.Page with
                                PageNumber =
                                    if (model.Query.Page.PageNumber > 0) then
                                        model.Query.Page.PageNumber - 1
                                    else
                                        model.Query.Page.PageNumber } } }

        let cmd =
            Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | Search value ->
        let model =
            { model with
                Input = value
                Query = { model.Query with Page = { model.Query.Page with PageNumber = 0 } } }

        let cmd = Cmd.OfAsync.either RatesApi.search (value, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | SetEditable value ->
        let newRateMappingModelList =
            model.Rates
            |> List.map (fun x ->
                if x.Rate.RateCode = value.Rate.RateCode then
                    { x with IsEditable = not x.IsEditable }
                else
                    x)

        let model = { model with Rates = newRateMappingModelList }
        model, Cmd.none
    | SetRatePlanCode (rateModel, gds, gdsRate) ->
        Fable.Core.JS.console.log("gds rate =", gdsRate)
        let newRateModel =
            { rateModel with
                EditableRate =
                    { rateModel.EditableRate with
                        RatePlanCodes =
                            rateModel.EditableRate.RatePlanCodes
                            |> List.map (fun x ->
                                if x.GdsCode = gds then
                                    { x with RatePlanCode = gdsRate.ToUpperInvariant() }
                                else
                                    x) } }

        let model =
            { model with
                Rates =
                    model.Rates
                    |> List.map (fun x ->
                        if x.Rate.RateCode = newRateModel.Rate.RateCode then
                            newRateModel
                        else
                            x) }

        model, Cmd.none
    | ModifyRate value ->

        let newRatePlanCodes =
            value.EditableRate.RatePlanCodes
                |> List.map
                    (fun x ->
                        match x.RatePlanCode with
                        | "" -> { x with RatePlanCode = "***" }
                        | _ -> x
                    )

        let privRate =
            { Private = 1
              RateCode = value.EditableRate.RateCode
              Program = value.EditableRate.Program
              Type = 0
              AllowsIataGuarantee = 0
              AccessCode = value.EditableRate.AccessCode
              RatePlanCodes = newRatePlanCodes   
            }

        let model =
            { model with
                Rates =
                    model.Rates
                    |> List.map (fun x ->
                        if ( x.Rate.RateCode = value.EditableRate.RateCode ) then
                            { x with 
                                EditableRate =
                                { x.EditableRate with
                                    RatePlanCodes = newRatePlanCodes
                                }
                            }
                        else
                            x
                    )
            }

        let cmd =
            Cmd.OfAsync.either RatesApi.updateRate privRate Msg.ModifyRateSuccess Msg.ModifyRateFail
           
        model, cmd
    | ModifyRateSuccess value ->
        let model =
            match value with
            | Ok(rateCode) ->
                { model with
                    Modal =
                        Some
                            { Message =
                                "The gds rates of "
                                + rateCode
                                + " has been modify with success"
                              RateCode = rateCode
                              Confirmation = false }
                    Rates =
                        model.Rates
                        |> List.map (fun x ->
                            if x.Rate.RateCode = rateCode then
                                { x with
                                    IsEditable = false
                                    Rate = x.EditableRate }
                            else
                                x) }
            | Error (message) ->
                { model with
                    Modal =
                        Some
                            { Message = message
                              RateCode = ""
                              Confirmation = false } }

        model, Cmd.none
    | ModifyRateFail exn ->
        let model =
            { model with
                Modal =
                    Some
                        { Message = exn.Message
                          RateCode = ""
                          Confirmation = false } }

        model, Cmd.none
    | DeleteRate value ->
        let cmd =
            Cmd.OfAsync.either RatesApi.deleteRate value Msg.DeleteRateSucceeded Msg.DeleteRateFail

        model, cmd
    | DeleteRateSucceeded value ->
        let model =
            { model with
                Modal =
                    Some
                        { Message =
                            "the rate "
                            + value
                            + " has been deleted with success"
                          RateCode = value
                          Confirmation = false } }

        model, Cmd.ofMsg SetRates
    | DeleteRateFail exn ->
        let model =
            { model with
                Modal =
                    Some
                        { Message = exn.Message
                          RateCode = ""
                          Confirmation = false } }

        model, Cmd.none
    | DownloadFile ->
        let cmd = Cmd.OfAsync.perform RatesApi.file () Msg.DownloadedFile
        model, cmd
    | DownloadedFile file ->
        file.SaveFileAs("export.csv", "text/csv")
        model, Cmd.none
    | OpenModal (action, rate) ->
        let model =
            { model with
                Modal =
                    Some
                        { Message = "Are you sure to " + action + " " + rate + " ?"
                          RateCode = rate
                          Confirmation = true } }

        model, Cmd.none
    | CloseModal ->
        let model =
            { model with
                Modal =
                    Some
                        { Message = ""
                          RateCode = ""
                          Confirmation = false }
                PostResult = "" }

        model, Cmd.none
    | CloseBanner ->
        let model =
            { model with Status = "" }
        model, Cmd.none


open Feliz
open Feliz.Bulma

[<ReactComponent>]
let AddRateForm (result: string) (action: Rate -> unit) =
    let (rateCode, setRateCode) = React.useState("")
    let (program, setProgram) = React.useState("")
    let (contractName, setContractName) = React.useState("")
    let (amadeusCode, setAmadeusCode) = React.useState("")
    let (sabreCode, setSabreCode) = React.useState("")
    let (galileoCode, setGalileoCode) = React.useState("")
    let (worldSpanCode, setWorldSpanCode) = React.useState("")
    let (pegasusCode, setPegasusCode) = React.useState("")

    Html.form [
        prop.style [
            style.display.flex
        ]
        prop.classes [
            "remove_spacing"
        ]
        prop.children [
                    
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "Booking code"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.span [
                        prop.className "red"
                        prop.text "*"
                    ]
                    Html.div [
                        Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size"
                            prop.required true
                            prop.value rateCode
                            prop.onChange (fun x -> setRateCode (CommonComponent.normalizeRateCode x 3))
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "Program"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.span [
                        prop.className "red"
                        prop.text "*"
                    ]
                    Html.div [
                        Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size2"
                            prop.required true 
                            prop.value program
                            prop.onChange (fun x -> setProgram (CommonComponent.normalize x))
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "Contract Name"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.span [
                        prop.className "red"
                        prop.text "*"
                    ]
                    Html.div [
                        Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size2"
                            prop.required true 
                            prop.value contractName
                            prop.onChange (fun x -> setContractName (CommonComponent.normalize x))
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "1A rate code"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.div [
                        Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size"
                            prop.value amadeusCode
                            prop.onChange (fun x -> setAmadeusCode (CommonComponent.normalizeRateCode x 3))
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "AA rate code"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.div [
                        Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size"
                            prop.value sabreCode
                            prop.onChange (fun x -> setSabreCode (CommonComponent.normalizeRateCode x 3))
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "UA rate code"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.div [
                         Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size"
                            prop.value galileoCode
                            prop.onChange (fun x -> setGalileoCode (CommonComponent.normalizeRateCode x 6))
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "1P rate code"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.div [
                        Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size"
                            prop.value worldSpanCode
                            prop.onChange (fun x -> setWorldSpanCode (CommonComponent.normalizeRateCode x 3))
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.span [
                        prop.text "WB rate code"
                        prop.style [
                            style.fontWeight 700
                        ]
                    ]
                    Html.div [
                        Html.input [
                            prop.type' "text"
                            prop.className "gds_code_size"
                            prop.value pegasusCode
                            prop.onChange (fun x -> setPegasusCode (CommonComponent.normalizeRateCode x 3))
                        ]
                    ]
                ]
            ]
            Bulma.field.div [
                Bulma.field.isGrouped
                Bulma.field.isGroupedCentered
                prop.style [
                    style.paddingTop 18
                ]
                prop.children [
                    Bulma.control.div [
                        prop.children[
                            Bulma.button.a [
                                prop.text "Save"
                                prop.classes [
                                    "avp-button"
                                    "button--secondary"
                                    "button--solid"
                                    "button--medium"
                                ]
                                let newrate =
                                    { ContractName = contractName
                                      Program = program
                                      RateCode = rateCode
                                      AmadeusCode = amadeusCode
                                      SabreCode = sabreCode
                                      GalileoCode = galileoCode
                                      WorldSpanCode = worldSpanCode
                                      PegasusCode = pegasusCode }

                                prop.onClick (fun _ -> action newrate)
                            ]

                        ]

                    ]
                ]
            ]
        ]
    ]

[<ReactComponent>]
let AddBanner status result (dispatch: Msg -> unit) =
    match status with 
        | "Success" -> Html.div [
                        prop.className "avp-banner-message banner-message--validation"
                        prop.children [
                            Html.i [
                                prop.className "banner-message-icon"
                                prop.ariaHidden true
                            ]
                            Html.div [
                                prop.className "banner-message-wrap-left"
                                prop.children [
                                    Html.p [
                                        prop.className "banner-message-title"
                                        prop.text "Success"
                                    ]
                                    Html.p [
                                        prop.className "banner-message-text"
                                        prop.text (result.ToString())
                                    ]
                                ]
                            ]
                            Html.div [
                                prop.className "banner-message-wrap-right"
                                prop.children [
                                    Html.button [
                                        prop.className "banner-message-button"
                                        prop.type'.button
                                        prop.text "Close"
                                        prop.onClick (fun _ -> dispatch CloseBanner)
                                    ]
                                ]
                            ]
                        ]
                    ]
        | "Failed" -> Html.div [
                        prop.className "avp-banner-message banner-message--error"
                        prop.children [
                            Html.i [
                                prop.className "banner-message-icon"
                                prop.ariaHidden true
                            ]
                            Html.div [
                                prop.className "banner-message-wrap-left"
                                prop.children [
                                    Html.p [
                                        prop.className "banner-message-title"
                                        prop.text "Error"
                                    ]
                                    Html.p [
                                        prop.className "banner-message-text"
                                        prop.text (result.ToString())
                                    ]
                                ]
                            ]
                            Html.div [
                                prop.className "banner-message-wrap-right"
                                prop.children [
                                    Html.button [
                                        prop.className "banner-message-button"
                                        prop.type'.button
                                        prop.text "Close"
                                        prop.onClick (fun _ -> dispatch CloseBanner)
                                    ]
                                ]
                            ]
                        ]
                    ]
        | _ -> Html.div[]


let containerBox (model: Model) (dispatch: Msg -> unit) =

    Html.div [
        prop.className "content"
        prop.style [
            style.display.inlineGrid
        ]
        prop.children [
            Html.div [
                Bulma.container.isWidescreen
                prop.classes[
                    "search_spacing"
                    "is-pulled-left"
                    "u-row"
                ]
                prop.style [
                    style.width 500
                ]
                prop.children [
                    Html.div [
                        prop.classes ["u-col-md-6"]
                        prop.children [
                            Bulma.input.text [
                                prop.placeholder "Search"
                                prop.classes ["avp-textinput"]
                                prop.value model.Input
                                prop.onChange (fun x -> SetInput x |> dispatch)
                            ]
                        ]
                    ]
                    Html.div [
                        prop.classes [ "u-col-md-2"]
                        prop.style [
                            style.paddingRight 0
                            style.width 50
                        ]
                        prop.children[
                            Bulma.button.a [
                                prop.children [
                                    Html.i [
                                        prop.className "fa-solid fa-magnifying-glass"
                                    ]
                                ]
                                prop.classes [
                                    "avp-button"
                                    "button--secondary"
                                    "button--solid"
                                    "button--medium"
                                ]
                                prop.onClick (fun _ -> Search model.Input |> dispatch)
                            ]
                        ]
                    ]
                    Html.div [
                        prop.classes [ "u-col-md-2"]
                        prop.style [
                            style.paddingLeft 0
                            style.width 50
                        ]
                        prop.children [
                            Bulma.button.a [
                                prop.children [
                                    Html.i [
                                        prop.className "fa-light fa-xmark"
                                    ]
                                ]
                                prop.classes [
                                    "avp-button"
                                    "button--secondary"
                                    "button--solid"
                                    "button--medium"
                                ]
                                prop.onClick (fun _ -> dispatch SetRates)
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.style [
                    style.display.flex
                    style.justifyContent.spaceBetween
                    style.paddingTop 10
                ]
                prop.children [
                    CommonComponent.pagination
                        model.Query.Page.ElemPerPage
                        model.Query.Page.PageNumber
                        model.PageMax
                        (SetElementPerPage >> dispatch)
                        (fun _ -> PreviousList |> dispatch)
                        (fun _ -> NextList |> dispatch)

                    Html.div [
                        prop.children [
                            Bulma.button.a [
                                prop.text "Export"
                                prop.style [
                                    style.float'.right
                                ]
                                prop.classes [
                                    "avp-button"
                                    "button--secondary"
                                    "button--solid"
                                    "button--medium"
                                ]
                                prop.onClick (fun _ -> dispatch DownloadFile)
                            ]
                        ]
                    ]
                ]
            ]
            Html.div [
                prop.children [
                    Html.table [
                        Html.thead [
                            Html.tr [
                                Html.th "Booking code"
                                Html.th "Program"
                                Html.th "Access code"
                                Html.th "1A rate code Amadeus"
                                Html.th "AA rate code Sabre"
                                Html.th "UA rate code Galileo / Apollo"
                                Html.th "1P rate code Worldspan"
                                Html.th "WB rate code Pegasus / dhisco"
                                if model.User.Role = "write" then
                                    Html.th "Action"
                            ]
                        ]
                        Html.tbody [
                            for rate in model.Rates do
                                let accescode = rate.Rate.AccessCode

                                Html.tr [
                                    if rate.IsEditable = false then

                                        Html.td [ prop.text rate.Rate.RateCode ]
                                        Html.td [ prop.text rate.Rate.Program ]
                                        Html.td [ prop.text accescode ]

                                        for plan in rate.Rate.RatePlanCodes do
                                            Html.td [ prop.text plan.RatePlanCode ]

                                        if model.User.Role = "write" then
                                            Html.td [
                                            if model.User.Role = "write" then
                                                CommonComponent.ButtonDropdown {Label = "Edit"; Action = (fun _ -> SetEditable rate |> dispatch) } [{Label = "Delete"; Action = (fun _ -> OpenModal("delete", rate.Rate.RateCode) |> dispatch) }]
                                            ]
                                    else
                                        Html.td [
                                            prop.text rate.EditableRate.RateCode
                                        ]

                                        Html.td [
                                            prop.text rate.EditableRate.Program
                                        ]

                                        Html.td [ prop.text accescode ]

                                        for plan in rate.EditableRate.RatePlanCodes do
                                            Html.td [
                                                Html.input [
                                                    prop.type' "text"
                                                    prop.className "gds_code_size"
                                                    prop.value plan.RatePlanCode
                                                    prop.onChange (fun x ->
                                                        SetRatePlanCode(rate, plan.GdsCode, CommonComponent.normalizeRateCode x 6 ) |> dispatch)
                                                ]
                                            ]

                                        Html.td [
                                            Html.div [
                                                prop.children [
                                                    Bulma.button.a [
                                                        prop.text "OK"
                                                        prop.classes [
                                                            "avp-button"
                                                            "button--secondary"
                                                            "button--solid"
                                                            "button--medium"
                                                        ]

                                                        prop.onClick (fun _ -> ModifyRate rate |> dispatch)
                                                    ]
                                                ]
                                            ]
                                        ]

                                        Html.td [
                                            Bulma.button.a [
                                                prop.text "Cancel"
                                                prop.classes [
                                                    "avp-button"
                                                    "button--sensitive"
                                                    "button--medium"
                                                ]
                                                prop.onClick (fun _ -> SetEditable rate |> dispatch)
                                            ]
                                        ]
                                    ]

                            if model.Modal.IsSome then
                                if model.Modal.Value.Confirmation = true then
                                    ModalComponentConfirmation
                                        (model.Modal.Value.Message)
                                        ("Delete")
                                        (fun _ -> DeleteRate model.Modal.Value.RateCode |> dispatch)
                                        (fun _ -> dispatch CloseModal)
                                else
                                    ModalComponentResult (model.Modal.Value.Message) (fun _ -> dispatch CloseModal)

                        ]
                    ]
                ]
            ]
            CommonComponent.pagination
                model.Query.Page.ElemPerPage
                model.Query.Page.PageNumber
                model.PageMax
                (SetElementPerPage >> dispatch)
                (fun _ -> PreviousList |> dispatch)
                (fun _ -> NextList |> dispatch)
        ]
    ]
    


let view (model: Model) (dispatch: Msg -> unit) =
    Bulma.container [
        Bulma.title [
            prop.text "Private rate codes"
            prop.style[
                style.paddingLeft 10
                style.lineHeight 46
                style.fontWeight 300
                style.fontSize 30
            ]
        ]
        Bulma.column [
            column.is12
            column.isOneFifth
            prop.children [
                Html.h2 [
                    text.hasTextLeft
                    prop.text "Create a private rate"
                    prop.className "blue"
                ]
                if model.User.Role = "write" then
                   AddBanner model.Status model.PostResult dispatch
                   AddRateForm model.PostResult (fun newrate -> AddRate newrate |> dispatch)
                else
                    Html.h2 [
                        prop.text "The access of the adding rate form is denied"
                        prop.style [
                            style.textAlign.center
                            style.marginTop 300
                        ]
                    ]
            ]
        ]
        Bulma.column [
            column.is12
            column.isOneFifth
            prop.children [
                Html.h2 [
                    text.hasTextCentered
                    prop.text "Rate codes list"
                    prop.className "blue"
                ]
                containerBox model dispatch
                |> orLoader model.IsLoading
                |> orErrorMessage model.ErrorMessage
            ]
        ]
    ]