module Index

open Elmish
open Fable.Remoting.Client
open Shared
open Feliz
open Feliz.Bulma
open Fable.Core.JsInterop
open CommonComponent

let RatesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IRatesApi>

type Page =
    | ConnectionPage
    | AuthorisationFailedPage
    | PublicRatesPage
    | PrivateRatesPage

type ModuleModel =
    | ConnectionModel
    | AuthorisationFailedModel of AuthorisationFailed.Model
    | PublicRatesModel of PublicRates.Model
    | PrivateRatesModel of PrivateRates.Model

type Model =
    { CurrentPage: Page
      ModuleModel: ModuleModel }

type Msg =
    | Init
    | GoogleConnected
    | PageWithUser of User
    | CheckEmailSuccess of bool
    | CheckEmailFailed of exn
    | AuthorisationFailedMsg of AuthorisationFailed.Msg
    | PublicRatesMsg of PublicRates.Msg
    | PrivateRatesMsg of PrivateRates.Msg

let init (page: Page option) : Model * Cmd<Msg> =

    let cookie = getCookie "token"
    let (token: Token option) =
        if System.String.IsNullOrEmpty(cookie) then
            None
        else
            Some(decodeJwtResponse (cookie))

    let page =
        match token with
        | Some token when not (System.String.IsNullOrEmpty(token.email)) -> page
        | _ -> None
        |> Option.defaultValue ConnectionPage
    
    match page with
    | ConnectionPage ->
        { CurrentPage = page
          ModuleModel = ConnectionModel },
        Cmd.none
    | AuthorisationFailedPage ->
        let pageModel, pageCmd = AuthorisationFailed.init ()

        { CurrentPage = AuthorisationFailedPage
          ModuleModel = AuthorisationFailedModel pageModel },
        Cmd.map AuthorisationFailedMsg pageCmd
    | PrivateRatesPage ->
        let pageModel, pageCmd = PrivateRates.init ()

        { CurrentPage = PrivateRatesPage
          ModuleModel = PrivateRatesModel pageModel },
        Cmd.map PrivateRatesMsg pageCmd
    | PublicRatesPage ->
        let pageModel, pageCmd = PublicRates.init ()

        { CurrentPage = PublicRatesPage
          ModuleModel = PublicRatesModel pageModel },
        Cmd.map PublicRatesMsg pageCmd


let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    let cookie = getCookie "token"
    let (decode: Token) = decodeJwtResponse (cookie)
    match msg, model.ModuleModel with
    | Init, _ -> init (Some ConnectionPage)
    | GoogleConnected, _ ->
        
        let cmd =
            Cmd.OfAsync.either RatesApi.checkEmailRight decode.email CheckEmailSuccess CheckEmailFailed

        model, cmd
    | CheckEmailSuccess isSuccess, _ ->
        Fable.Core.JS.console.log ("CheckEmailSuccess : ", isSuccess)

        match isSuccess with
        | true ->
            let pageModel, pageCmd = PrivateRates.init ()
           
            { CurrentPage = PrivateRatesPage
              ModuleModel = PrivateRatesModel pageModel },
            Cmd.map PrivateRatesMsg pageCmd
        | false ->
            let pageModel, pageCmd = AuthorisationFailed.init ()

            { CurrentPage = AuthorisationFailedPage
              ModuleModel = AuthorisationFailedModel pageModel },
            Cmd.map AuthorisationFailedMsg pageCmd
    | CheckEmailFailed exn, _ ->
        Fable.Core.JS.console.log ("CheckEmailFailed", exn)
        model, Cmd.none
    | AuthorisationFailedMsg authMsg, AuthorisationFailedModel authModel ->
        let model, cmd = AuthorisationFailed.update authMsg authModel

        { CurrentPage = AuthorisationFailedPage
          ModuleModel = AuthorisationFailedModel model },
        Cmd.map AuthorisationFailedMsg cmd
    | PublicRatesMsg publicRatesMsg, PublicRatesModel publicRatesModel ->
        let model, cmd = PublicRates.update publicRatesMsg publicRatesModel

        { CurrentPage = PublicRatesPage
          ModuleModel = PublicRatesModel model },
        Cmd.map PublicRatesMsg cmd
    | PrivateRatesMsg privateRatesMsg, PrivateRatesModel privateRatesModel ->
        let model, cmd = PrivateRates.update privateRatesMsg privateRatesModel

        { CurrentPage = PrivateRatesPage
          ModuleModel = PrivateRatesModel model },
        Cmd.map PrivateRatesMsg cmd
    | _, _ -> model, Cmd.none


let navBrand (model: Model) =
    Html.div [
        prop.className "avp-header"
        prop.children [
            Html.div [
                prop.className "avp-header__mono avp-header__mono_theme_main"
                prop.children [
                    Html.div [
                        prop.className "avp-header__mono__container"
                        prop.children [
                            Html.div [
                                prop.className "avp-header__mono__logo header-logo"
                                prop.children [
                                    Html.a [
                                        prop.className "header-logo__link"
                                        prop.href "#private-rates"
                                        prop.children [
                                            Html.img [
                                                prop.className "logo-size"
                                                prop.src "https://extranet.staging.d-edge.app/ContentDeliveryNetwork/Header/Content/Images/SmallLogo.svg"
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                            Html.nav [
                                prop.className "avp-header__mono__main-nav header-main-nav"
                                prop.children [
                                    Html.ul [
                                        prop.className "header-main-nav__nav"
                                        prop.children [
                                            Html.li [
                                                if model.CurrentPage = PublicRatesPage then
                                                    prop.className "header-main-nav__nav__item header-main-nav__nav__item_selected"
                                                else
                                                    prop.className "header-main-nav__nav__item"
                                                prop.children [
                                                    Html.a [
                                                        prop.className "google-analytics-tracker header-main-nav__nav__link header-main-nav__nav__link_theme_main"
                                                        prop.href "#public-rates"
                                                        prop.text "Public Rates"

                                                    ]
                                                ]
                                            ]
                                            Html.li [
                                                if model.CurrentPage = PrivateRatesPage then
                                                    prop.className "header-main-nav__nav__item header-main-nav__nav__item_selected"
                                                else
                                                    prop.className "header-main-nav__nav__item"
                                                prop.children [
                                                    Html.a [
                                                        prop.className "google-analytics-tracker header-main-nav__nav__link header-main-nav__nav__link_theme_main"
                                                        prop.href "#private-rates"
                                                        prop.text "Private Rates"
                                                    ]
                                                ]

                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let view (model: Model) (dispatch: Msg -> unit) =
    let connexion =
        Html.div [
            Html.h1 "Connection Page"
            Html.button [
                prop.style [ style.display.none ]
                prop.id "google-btn"
                prop.onClick (fun _ -> GoogleConnected |> dispatch)
            ]
        ]

    let withMenu model content =
        Bulma.hero [
            prop.style [
                style.backgroundColor "#FAF9F5"
            ]
            prop.children [
                Bulma.heroHead [
                    navBrand model
                ]
                content
            ]
        ]

    match model.ModuleModel with
    | ConnectionModel -> connexion
    | AuthorisationFailedModel m -> AuthorisationFailed.view m (AuthorisationFailedMsg >> dispatch)
    | PublicRatesModel m ->
        PublicRates.view m (PublicRatesMsg >> dispatch)
        |> withMenu model 
    | PrivateRatesModel m ->
        PrivateRates.view m (PrivateRatesMsg >> dispatch)
        |> withMenu model


module Navigation =
    open Elmish.UrlParser

    let pageParser: Parser<_, Page> =
        oneOf [
            map ConnectionPage (s "connexion")
            map AuthorisationFailedPage (s "access-denied")
            map PublicRatesPage (s "public-rates")
            map PrivateRatesPage (s "private-rates")
        ]

    let urlUpdate (page: Page option) _ =
        Fable.Core.JS.console.log(page)
        let page = page |> Option.defaultValue ConnectionPage
        let model, cmd = Some page |> init
        { model with CurrentPage = page }, cmd