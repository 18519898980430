module PublicRates

open Elmish
open Shared
open Fable.Remoting.Client
open CommonComponent

type Model =
    { Rates: RatesMapping list
      Input: string
      PlanCode: RatePlanCode list
      Remainder: int
      PageMax: int
      Query: Query
      IsLoading: bool
      ErrorMessage: string option
    }

type Msg =
    | Init
    | SetRates
    | GotRatesSucceeded of FilteredRates
    | GotRatesFailed of exn
    | SetInput of string
    | NextList
    | PreviousList
    | Search of string
    | SetElementPerPage of int

let RatesApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IRatesApi>

let init () : Model * Cmd<Msg> =
    let model =
        { Input = ""
          Rates = []
          PlanCode = []
          Remainder = 0
          PageMax = 0
          Query =
            { Page = { PageNumber = 0; ElemPerPage = 10 }
              Public = true }
          IsLoading = true
          ErrorMessage = None }

    let cmd = Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) GotRatesSucceeded GotRatesFailed

    model, cmd

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | GotRatesSucceeded (filter) ->
        { model with
            Rates = filter.RatesList
            Remainder = filter.NumberTotalElements % model.Query.Page.ElemPerPage
            PageMax =
                filter.NumberTotalElements
                / model.Query.Page.ElemPerPage
            IsLoading = false },
        Cmd.none
    | GotRatesFailed (ex) ->
        Fable.Core.JS.console.log ex.Message
        { model with
            IsLoading = false
            ErrorMessage = Some "An error occurred during data loading. Please check your connection or try again later."
        },
        Cmd.none
    | SetRates ->
        let model =
            { model with
                Input = ""
                Query = { model.Query with Page = { model.Query.Page with PageNumber = 0 } } }

        model, Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed
    | SetInput value -> { model with Input = value }, Cmd.none
    | NextList ->
        let model =
            { model with
                Query =
                    { model.Query with
                        Page =
                            { model.Query.Page with
                                PageNumber =
                                    if (model.Query.Page.PageNumber < model.PageMax) then
                                        model.Query.Page.PageNumber + 1
                                    else
                                        model.Query.Page.PageNumber } } }

        let cmd =
            Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | PreviousList ->
        let model =
            { model with
                Query =
                    { model.Query with
                        Page =
                            { model.Query.Page with
                                PageNumber =
                                    if (model.Query.Page.PageNumber > 0) then
                                        model.Query.Page.PageNumber - 1
                                    else
                                        model.Query.Page.PageNumber } } }

        let cmd =
            Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | Search value ->
        let model =
            { model with
                Input = value
                Query = { model.Query with Page = { model.Query.Page with PageNumber = 0 } } }

        let cmd = Cmd.OfAsync.either RatesApi.search (value, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | SetElementPerPage value ->
        let model =
            { model with Query = { model.Query with Page = { model.Query.Page with ElemPerPage = value } } }

        let cmd =
            Cmd.OfAsync.either RatesApi.search (model.Input, model.Query) Msg.GotRatesSucceeded Msg.GotRatesFailed

        model, cmd
    | _ -> model, Cmd.none


open Feliz
open Feliz.Bulma



let containerBox (model: Model) (dispatch: Msg -> unit) =
    Html.div [
        prop.className "content"
        prop.style [
            style.display.inlineGrid
        ]
        prop.children [
            Bulma.field.div [
                Bulma.container.isWidescreen
                prop.classes[
                    "is-pulled-left"
                ]
                prop.style [
                    style.width 500
                ]
                prop.children [
                    Html.div [
                        prop.classes ["u-col-md-6"]
                        prop.style [
                            style.width 344
                            style.paddingLeft 0
                        ]
                        prop.children [
                            Bulma.input.text [
                                prop.placeholder "Search"
                                prop.classes ["avp-textinput"]
                                prop.value model.Input
                                prop.onChange (fun x -> SetInput x |> dispatch)
                            ]
                        ]
                    ]
                    Html.div [
                        prop.classes [ "u-col-md-2"]
                        prop.style [
                            style.paddingRight 0
                            style.width 50
                        ]
                        prop.children[
                            Bulma.button.a [
                                prop.children [
                                    Html.i [
                                        prop.className "fa-solid fa-magnifying-glass"
                                    ]
                                ]
                                prop.classes [
                                    "avp-button"
                                    "button--secondary"
                                    "button--solid"
                                    "button--medium"
                                ]
                                prop.onClick (fun _ -> Search model.Input |> dispatch)
                            ]
                        ]
                    ]
                    Html.div [
                        prop.classes [ "u-col-md-2"]
                        prop.style [
                            style.paddingLeft 0
                            style.width 50
                        ]
                        prop.children [
                            Bulma.button.a [
                                prop.children [
                                    Html.i [
                                        prop.className "fa-light fa-xmark"
                                    ]
                                ]
                                prop.classes [
                                    "avp-button"
                                    "button--secondary"
                                    "button--solid"
                                    "button--medium"
                                ]
                                prop.onClick (fun _ -> dispatch SetRates)
                            ]
                        ]
                    ]
                ]
            ]
            CommonComponent.pagination
                model.Query.Page.ElemPerPage
                model.Query.Page.PageNumber
                model.PageMax
                (SetElementPerPage >> dispatch)
                (fun _ -> PreviousList |> dispatch)
                (fun _ -> NextList |> dispatch)

            Bulma.field.div [
                prop.children [
                    Html.table [
                        Html.thead [
                            Html.th [
                                prop.text "Booking Code"
                                prop.style [
                                    style.width 140
                                    style.height 68
                                    style.padding 16
                                    style.gap 4
                                ]
                            ]
                            Html.th [
                                prop.text "Program"
                                prop.style [
                                    style.width 240
                                    style.height 68
                                    style.padding 16
                                ]
                            ]
                            Html.th [
                                prop.text "Access key"
                                prop.style [
                                    style.width 400
                                    style.height 68
                                    style.padding 16
                                ]
                            ] 
                            Html.th [
                                prop.text "1A rate code Amadeus"
                                prop.style [
                                    style.width 140
                                    style.height 68
                                    style.padding 16
                                ]
                            ]
                            Html.th [
                                prop.text "AA rate code Sabre"
                                prop.style [
                                    style.width 140
                                    style.height 68
                                    style.padding 16
                                ]
                            ]
                            Html.th [
                                prop.text "UA rate code Galileo"
                                prop.style [
                                    style.width 140
                                    style.height 68
                                    style.padding 16
                                ]
                            ]
                            Html.th [
                                prop.text "1P rate code Worldspan"
                                prop.style [
                                    style.width 140
                                    style.height 68
                                    style.padding 16
                                ]
                            ]
                            Html.th [
                                prop.text "WB rate code Pegasus"
                                prop.style [
                                    style.width 140
                                    style.height 68
                                    style.padding 16
                                ]
                            ]
                        ]
                        Html.tbody [
                            for rate in model.Rates do
                                let acccescode = "Gds-" + rate.RateCode + "-" + rate.Program
                            
                                Html.tr [
                                
                                    Html.td [ prop.text rate.RateCode ]
                                    Html.td [ prop.text rate.Program ]
                                    Html.td [ prop.text acccescode ]
                                    for plan in rate.RatePlanCodes do
                                        Html.td [ prop.text plan.RatePlanCode ]
                                ]
                        ]
                    ]
                ]
            ]
            CommonComponent.pagination
                model.Query.Page.ElemPerPage
                model.Query.Page.PageNumber
                model.PageMax
                (SetElementPerPage >> dispatch)
                (fun _ -> PreviousList |> dispatch)
                (fun _ -> NextList |> dispatch)
        ]
    ]

let view (model: Model) (dispatch: Msg -> unit) =

    Bulma.container [
        Bulma.column [
            column.is12
            column.isOneFifth
            prop.style [
                style.backgroundColor "#FAF9F5"
            ]
            prop.children [
                Bulma.title [
                    prop.text "Public rate codes"
                    prop.style[
                        style.paddingLeft 10 
                        style.lineHeight 46
                        style.fontWeight 300
                        style.fontSize 30
                    ]
                ]
                containerBox model dispatch
                |> orLoader model.IsLoading
                |> orErrorMessage model.ErrorMessage
            ]
       ]
    ]