module CommonComponent

open Feliz
open Feliz.Bulma
open ExtranetUIKit

type FilterPopover =
    { Visible: bool
      Size: string
      PositionY: string
      PositionX: string
      TargetRef: (Browser.Types.HTMLElement) }

let orLoader (isLoading: bool) (content: ReactElement) =
    if isLoading then
        Bulma.button.button [
            Bulma.button.isLarge
            Bulma.button.isFullWidth
            Bulma.button.isLoading
        ]
    else
        content

let orErrorMessage (errorMessage: string option) (content: ReactElement) =
    match errorMessage with
    | Some message ->
        Bulma.box message
    | None -> content

[<ReactComponent>]
let pagination (itemCountPerPage:int) (currentPageIndex:int) (maxIndex:int) (onItemPerPageChanged:int->unit) (onPreviousClicked:unit->unit) (onNextClicked:unit->unit) =

    Html.div [
        prop.style [
            style.display.flex
            style.alignItems.center
            style.gap 10
        ]
        prop.children [
            Bulma.select [                
                prop.onChange (fun (x:string) -> onItemPerPageChanged (x |> int))
                prop.value itemCountPerPage

                let dropdownValues = [ "10"; "50"; "100" ]
                prop.children [
                    for value in dropdownValues do
                        Html.option [ prop.text value ]
                ]

                prop.classes [ "avp-select" ]

            ]
            Html.text "per page"
            Bulma.button.a [
                prop.children [
                    Html.i [
                        prop.className "fa-solid fa-chevron-left"
                    ]
                ]
                let actif = if currentPageIndex = 0 then
                                "is-disabled"
                            else
                                ""
                prop.classes [
                    "avp-button"
                    "button--secondary"
                    "button--solid"
                    "button--medium"
                    actif
                ]
                prop.onClick (fun _ -> onPreviousClicked())
            ]
            let currentPage = "Page : " + (currentPageIndex + 1).ToString() + " / " + (maxIndex + 1).ToString()
            Html.text  currentPage
            Bulma.button.a [
                prop.children [
                    Html.i [
                        prop.className "fa-solid fa-chevron-right"
                    ]
                ]
                let actif = if maxIndex = currentPageIndex then
                                "is-disabled"
                            else
                                ""
                prop.classes [
                    "avp-button"
                    "button--secondary"
                    "button--solid"
                    "button--medium"
                    actif
                ]
                                
                prop.onClick (fun _ -> onNextClicked())
            ]
        ]
    ]

[<ReactComponent>]
let PopoverComponent () =

    let (isPopoverVisible, setPopoverVisible) = React.useState (false)
    let linkRef = React.useRef (None)

    let togglePopover () =
        setPopoverVisible (not isPopoverVisible)

    Html.div [
        prop.children [
            Bulma.button.a [
                prop.text "x"
                prop.custom ("ref", linkRef)
                prop.custom ("onClick", togglePopover)
            ]
            Popover.popover [ prop.custom ("visible", isPopoverVisible)
                              prop.custom ("targetRef", linkRef)
                              prop.custom ("onClose", togglePopover) ] [
                Popover.popoverBody [
                    Html.text "popover"
                ]
                Popover.popoverNav [
                    Popover.popoverNav []
                    Popover.popoverNavClose [
                        prop.custom ("onClose", togglePopover)
                    ]
                ]
            ]
        ]
    ]

[<ReactComponent>]
let ModalComponentConfirmation (success: string) (actionName: string) (actionFunction: unit -> unit) (close: (unit -> unit)) =

    Modal.modal [
        prop.custom ("size", "large")
        prop.custom ("opened", System.String.IsNullOrEmpty(success) |> not)
        prop.custom ("overlay", true) ] [
                Modal.modalBody [
                    Html.div [
                        prop.className "modal-exemple"
                        prop.text success
                    ]
                ]
                Modal.modalAction [
                    Bulma.button.a [
                        prop.text actionName
                        prop.custom ("level", "secondary")
                        prop.custom ("size", "medium")
                        prop.custom ("shape", "ghost")

                        prop.style [
                            style.backgroundColor "#2778c7"
                            style.color.white
                            style.textDecorationColor.white
                        ]

                        prop.custom ("onClick", (fun _ -> actionFunction() ))
                    ]
                    Bulma.button.a [
                        prop.text "Cancel"
                        prop.custom ("level", "secondary")
                        prop.custom ("size", "medium")
                        prop.custom ("shape", "ghost")
                        prop.style [
                            style.backgroundColor "#c23934"
                            style.color.white
                            style.textDecorationColor.white
                        ]
                        prop.custom ("onClick", close)
                    ]
                ]
            ]

[<ReactComponent>]
let ModalComponentResult (success: string) (close: (unit -> unit)) =

    Modal.modal [ prop.custom ("size", "large")
                  prop.custom ("opened", System.String.IsNullOrEmpty(success) |> not)
                  prop.custom ("overlay", true) ] [
        Modal.modalBody [
            Html.div [
                prop.className "modal-exemple"
                prop.text success
            ]
        ]
        Modal.modalAction [

            Bulma.button.a [
                prop.text "Close"
                prop.custom ("level", "secondary")
                prop.custom ("size", "medium")
                prop.custom ("shape", "ghost")
                prop.style [
                    style.backgroundColor "#c23934"
                    style.color.white
                    style.textDecorationColor.white
                ]
                prop.custom ("onClick", (fun _ -> close ()))
            ]
        ]
    ]

[<Fable.Core.Emit("getCookie($0)")>]
let getCookie (name: string) : string = Fable.Core.Util.jsNative

[<Fable.Core.Emit("decodeJwtResponse($0)")>]
let decodeJwtResponse token = Fable.Core.Util.jsNative

let normalize (input:string) =
    System.Text.RegularExpressions.Regex.Replace(input, "[^a-zA-Z0-9\-]", "").ToUpper()

let normalizeRateCode (input:string) (maxChar: int) =
    let reg = System.Text.RegularExpressions.Regex.Match(input, "^[a-zA-Z0-9\*]{0," + maxChar.ToString() + "}$")
    if reg.Success then
        input.ToUpper()
    else
        System.Text.RegularExpressions.Regex.Replace(input, "[^a-zA-Z0-9\*]", "").ToUpper().Substring(0, System.Math.Min(maxChar, input.Length))


let listenForOutsideClick(buttonRef:Fable.React.IRefValue<Browser.Types.HTMLElement option>, setOpen) =
    Browser.Dom.window.document.addEventListener(
        "click",
        fun (evt) ->
            let node = evt.target
            buttonRef.current
            |> Option.iter (fun cur ->
                if not (cur.contains (node:?>Browser.Types.Node)) then setOpen(false)
            )           
    )

type ButtonDropdownItem = {
    Label: string
    Action: (unit -> unit)
}
[<ReactComponent>]
let ButtonDropdown (main: ButtonDropdownItem) (buttons:ButtonDropdownItem list) =
    let (isOpen, setOpen) = React.useState(false)
    let buttonRef = React.useRef(None)

    React.useEffect(fun _ -> listenForOutsideClick(buttonRef, setOpen))

    let item (button:ButtonDropdownItem) =
        Html.li [
            Html.button [
                prop.type'.button
                prop.className "dropdown-menu-item"
                prop.text button.Label
                prop.onClick (fun _ ->
                    button.Action()
                    setOpen(false)
                )
            ]
        ]
 
    Html.div [
        prop.className "avp-dropdown"
        prop.children [
            Html.div [
                prop.className "avp-button-group"
                prop.children [
                    Html.a [
                        prop.className "avp-button button--tertiary button--solid button--medium"
                        prop.text main.Label
                        prop.onClick (fun _ -> main.Action())
                    ]
                    Html.button [
                        prop.id "secondary-solid-split-button-dropdown-toggle"
                        prop.classes [
                            "avp-button"
                            "button--tertiary"
                            "button--solid"
                            "button--medium"
                            "button--icon-only"
                            "dropdown-toggle"
                        ]
                        prop.type'.button
                        prop.ariaExpanded isOpen
                        prop.ariaControls "secondary-solid-split-dropdown-toggle"
                        prop.children [
                            Html.span [
                                prop.className "u-visually-hidden"
                                prop.text "Toggle dropdown"
                            ]
                            Html.i [
                                prop.className "far fa-chevron-down button-icon dropdown-toggle-icon"
                                prop.ariaHidden (not isOpen)
                            ]
                        ]
                        prop.onClick (fun e ->
                            setOpen(not isOpen)
                        )
                    ]
                ]
            ]
            Html.div [
                prop.id "secondary-solid-split-dropdown-toggle"
                prop.classes [
                    "dropdown-body"
                    "dropdown-body--small"
                    "dropdown-body--align-end"
                    "dropdown-body--menu"
                ]
                prop.ariaLabelledBy "secondary-solid-split-button-dropdown-toggle"
                prop.hidden (not isOpen)
                prop.children [
                    Html.ul [
                        prop.className "dropdown-menu"
                        prop.style [
                            style.display.block
                            style.position.relative
                            style.listStyleType.none
                            style.margin 0
                        ]
                        prop.children (buttons |> List.map item)
                    ]
                ]
            ]
        ]
    ]
