module AuthorisationFailed

open System
open Elmish

type Model =
    { Gmail: string
      Password: string}

type Msg =
    | Init

let init () : Model * Cmd<Msg> =
    let model =
        { Gmail = ""
          Password = "" }

    let cmd = Cmd.Empty

    model, cmd

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | _ -> model, Cmd.none

open Feliz
open Feliz.Bulma

let containerBox (model: Model) (dispatch: Msg -> unit) =
    Bulma.content [
         Html.div [
                prop.children [
                    Html.h2 [
                        prop.text "You are not authorized to see the Rates"
                    ]
                    Html.button [
                        prop.text "You shall not pass"
                        prop.id "google-btn"
                        prop.onClick (fun _ -> Init |> dispatch)
                    ]
                ]
            ]
    ]

let view (model: Model) (dispatch: Msg -> unit) =

    Bulma.box [
        prop.children [
            containerBox model dispatch
        ]
    ]